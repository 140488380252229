import React from "react";
import theme from "theme";
import { Theme, Box, Text, Hr, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakti"} />
		<Helmet>
			<title>
				SKILLENHANCE
			</title>
			<meta name={"description"} content={"Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань"} />
			<meta property={"og:title"} content={"SKILLENHANCE"} />
			<meta property={"og:description"} content={"Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header2 />
		<Section sm-padding="10px 0 30px 0" lg-padding="10px 0 60px 0" padding="10px 0 100px 0" quarkly-title="Statistics-12">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
			/>
			<Box width="100%" sm-width="100%" />
			<Box
				width="100%"
				sm-width="100%"
				border-width="0px 0px 1px 0px"
				border-style="solid"
				border-color="--color-lightD2"
			>
				<Text margin="0px 0px 20px 0px" font="--base" color="--dark" sm-margin="0px 0px 20px 0px">
					КОНТАКТНА ІНФОРМАЦІЯ
				</Text>
			</Box>
			<Box width="50%" sm-width="100%" margin="70px 0px 0px 0px" md-width="100%">
				<Text margin="0px 0px 20px 0px" font="--headline3" color="--dark" sm-margin="0px 0px 20px 0px">
					Не втрачайте можливості вдосконалити свої знання та розширити горизонти. Зателефонуйте нам і зробіть перший крок до вашого успіху.
				</Text>
			</Box>
			<Box width="50%" md-width="100%">
				<Box
					sm-margin="30px 0px 0px 0px"
					width="100%"
					sm-width="100%"
					margin="70px 0px 0px 0px"
					padding="0px 0px 0px 40px"
					sm-padding="0px 0px 0px 0px"
				>
					<Text
						color="--dark"
						sm-margin="0px 0px 20px 0px"
						letter-spacing="-.06em"
						lg-font="normal 400 54px/1.1em &quot;Inter&quot;, sans-serif"
						margin="0px 0px 54px 0px"
						font="--headline3"
					>
						0938844092
					</Text>
					<Hr margin="0px 0px 0px 0px" />
				</Box>
				<Box
					width="100%"
					sm-width="100%"
					margin="70px 0px 0px 0px"
					padding="0px 0px 0px 40px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="30px 0px 0px 0px"
				>
					<Text
						color="--dark"
						sm-margin="0px 0px 20px 0px"
						letter-spacing="-.06em"
						lg-font="normal 400 54px/1.1em &quot;Inter&quot;, sans-serif"
						margin="0px 0px 54px 0px"
						font="--headline3"
					>
						info@skillenhance24.live.com
					</Text>
					<Hr margin="0px 0px 0px 0px" />
				</Box>
				<Box
					sm-margin="30px 0px 0px 0px"
					width="100%"
					sm-width="100%"
					margin="70px 0px 0px 0px"
					padding="0px 0px 0px 40px"
					sm-padding="0px 0px 0px 0px"
				>
					<Text
						font="--headline3"
						color="--dark"
						sm-margin="0px 0px 20px 0px"
						letter-spacing="-.06em"
						lg-font="normal 400 54px/1.1em &quot;Inter&quot;, sans-serif"
						margin="0px 0px 54px 0px"
					>
						вулиця Зелена, 109,
						<br />
						{" "}офіс 444, Львів
					</Text>
					<Hr margin="0px 0px 0px 0px" />
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4">
				0938844092
			</Override>
			<Override slot="link5">
				info@skillenhance24.live.com
			</Override>
			<Override slot="link6">
				вулиця Зелена, 109,
				<br />
				{" "}офіс 444, Львів
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});